<script setup lang="ts">
const { data: notice } = await useFetch("/api/top/notice");

function removeHtml(text: string) {
  return text.replace(/(<([^>]+)>)/gi, "");
}
</script>

<template>
  <LayoutWrapperDiv v-if="notice?.length">
    <div class="border-2 border-red-500 p-4 relative">
      <h2 class="absolute -top-4 bg-white text-xl text-red-600 font-bold">
        お知らせ
      </h2>
      <ul class="text-center px-2">
        <li v-for="n in notice" :key="n.url">
          <NuxtLink
            class="text-red-600 font-bold hover:brightness-60"
            :to="n.url"
            target="_blank"
          >
            {{ removeHtml(n.text) }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </LayoutWrapperDiv>
</template>
