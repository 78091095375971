<script setup lang="ts">
import type { RecommendItem, RecommendItemType } from "~/types";
import { convertDispItem } from "~/utils/sekou/item";
import { categoryToAlphabet, categoryToJapanese } from "~/utils/sekou/category";

interface Props {
  // 表示モード TOP:トップページで使用 instagram:インスタグラムからのアクセスでフォームで使用
  mode?: "top" | "instagram";
}
const props = withDefaults(defineProps<Props>(), {
  mode: "top",
});

const [
  { data: recoitem },
  { data: review },
  attrs,
  { historyItems, fetch, status },
] = await Promise.all([
  // おすすめ商品
  useFetch(() => "/api/session/item"),
  // お客様レビュー
  useFetch(() => "/api/review/avg"),
  // 施工例の分類を取得
  useSekouAttr(),
  // 前回の続きがあれば先頭に
  useHistory(),
]);

// おすすめバナースライドを配列で返す
const { recommend } = await useRecommendSlide(
  getSekouSlides(),
  getOsusumeItems(),
  getReviewSlide(),
);

// 施工例
function getSekouSlides() {
  // 庭まわりリフォーム:36 ,新築:1
  const targetAttrs = attrs.value.filter(
    (e) => e.attr_num == 36 || e.attr_num === 1,
  );
  targetAttrs?.reverse();
  return (
    targetAttrs?.map((attr): RecommendItem => {
      return {
        type: "sekou",
        param: {
          text_list: [`${categoryToJapanese(attr.category)} 施工例`],
          count: attr.count,
        },
        path: `/images/recommend/${categoryToAlphabet(attr.category)}.png`,
        link: `/sekou/${categoryToAlphabet(attr.category)}${attr.attr_num}`,
        alt: "",
        preload: true,
        target: "",
      };
    }) ?? []
  );
}

// セッションに基づくおすすめ商品
function getOsusumeItems() {
  let _list: RecommendItem[] = [];
  if (recoitem.value?.length) {
    const dispItems = recoitem.value.map((item) => convertDispItem(item));

    // データから生成するものを順番に追加
    // おすすめ商品
    dispItems.forEach((item) => {
      let _item = <RecommendItem>{
        type: "item" as RecommendItemType,
        param: {
          category_name: "",
          item_name: item.item_name,
          count: addTax(item.j_price),
          // 割引率
          count2: item.item_off,
        },
        path: item.img_path,
        link: item.url,
        alt: "",
        preload: false,
        target: "",
      };
      _list.push(_item);
    });
  }
  return _list;
}

// レビュー
function getReviewSlide(): RecommendItem {
  return {
    type: "review",
    param: {
      count: review.value?.avg.enq_5 ?? 0,
      count2: review.value?.total ?? 0,
    },
    alt: "お客様レビュー満足度",
    path: "/images/recommend/review.png",
    link: "/e_list",
    preload: false,
    target: "",
  };
}

const history = computed<RecommendItem | null>(() => {
  const target = historyItems.value[0] ?? null;
  if (!target) return null;
  return {
    type: "text",
    param: {
      text_list: ["前回の続きを見る"],
    },
    path: target.img ?? "",
    link: target.link ?? "",
    alt: "",
    preload: true,
    target: "",
  };
});
onMounted(() => {
  if (!historyItems.value.length) {
    fetch();
  }
});

const isInstagram = computed(() => props.mode === "instagram");
</script>

<template>
  <div
    class="slide h-[213.5px] tablet:h-[249.5px] relative bg-gradient-to-b from-transparent to-gray-200 p-2"
  >
    <LoadingIndicator
      v-if="status == 'pending'"
      class="w-full flex justify-center items-center h-full"
    />

    <ul v-else-if="recommend" class="flex gap-2 overflow-x-auto">
      <SlideRecommendItem
        v-if="history"
        :data="history"
        :isInstagram="isInstagram"
      />
      <SlideRecommendItem
        v-for="(d, idx) in recommend"
        :key="idx"
        :data="d"
        :isInstagram="isInstagram"
      />
    </ul>
  </div>
</template>
