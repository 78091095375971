import type { RecommendItem } from "~/types";

export default async function (
  sekou: RecommendItem[],
  items: RecommendItem[],
  review: RecommendItem,
) {
  const local_site = useLocalSite();
  // 件数取得
  const { data } = await useTotalCount();

  // 並び順 Google口コミ->プラン->リフォーム->新築->人気施工例->mobi->レビュー
  const recommend: RecommendItem[] = [
    {
      type: "google_review",
      param: {
        count: local_site.google_map.star,
        count2: local_site.google_map.count,
      },
      alt: "外構・リフォーム工事の口コミレビュー",
      path: "/images/recommend/google.png",
      link: local_site.google_map.url,
      preload: false,
      target: "_blank",
    },
    {
      type: "sekou",
      param: {
        count: data.plan,
        text_list: ["プラン事例"],
      },
      alt: "外構・リフォーム工事のおすすめプラン事例",
      path: "/images/recommend/plan.png",
      link: "/plan",
      preload: true,
      target: "",
    },
    ...sekou,
    {
      type: "text",
      param: {
        text_list: ["先月の", "人気施工例", "ランキング"],
      },
      alt: "人気の外構・リフォーム・エクステリア工事の施工例ランキング",
      path: "/images/recommend/ranking.png",
      link: "/contents.php/ranking",
      preload: false,
      target: "",
    },
    ...items,
    review,
  ];

  return { recommend: recommend };
}
